import { useState } from 'react'
import Menu from './Menu'
import Logo from '../assets/images/axiom_driggs.svg'
import Bugs from './layout/Bugs'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationsIcon } from './pages/conversations/NotificationsIcon'
import { useConversations } from '../contexts/ConversationsContext'
import ch_images from '../assets/images/conversations'
import { BsToggleOn, BsToggles2 } from 'react-icons/bs'
import { CircleCheckToggle } from './form/CircleCheckToggle'
import { useClaims } from '../contexts/ClaimsContext'
import images_logos from '../assets/images/axiom_logos'
import images_tours from '../assets/images/listing_tours'
import { useListingTours } from '../contexts/ListingToursContext'
import { useProfile } from '../contexts/UserContext'
import useAxiomFetch from '../hooks/useAxiomFetch'
import { useAuth0 } from '@auth0/auth0-react'
import uiStyles from './ui/uiStyles'
import { SmallLoadingRel } from './ui/SmallLoadingRel'
import { useReceiver } from '../contexts/ReceiverContext'
import { get_storage } from '../helpers'
function Header2({
  type = 'contId',
  showConversations = true,
  showTours = true,
  navigateHome = false,
  show_agent_toggle = true,
}) {
  const { conversations, campaigns, convMessages, loadingConversations } =
    useConversations()
  const { claims, saveClaims } = useClaims()
  const { profile, saveProfile } = useProfile()
  const { listingTours } = useListingTours()
  const { pathname } = useLocation()
  const { receiver, saveReceiver } = useReceiver()
  const get_count = (id) => {
    let total = 0
    if (id === 'conversations') {
      for (const conversation of conversations) {
        total += convMessages?.[conversation?.sid]?.unread_count || 0
      }
      for (const campaign of Object.values(campaigns)) {
        console.log(campaign)
        for (const conv of campaign?.conversations) {
          total += convMessages[conv?.sid]?.unread_count || 0
        }
      }
    }
    return total
  }

  const { axiomFetch } = useAxiomFetch()
  const { user, loginWithRedirect } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [switchLoading, setSwitchLoading] = useState(false)
  const navigate = useNavigate()
  console.log('claims', claims, get_storage('uuid'), navigateHome)
  const switch_accounts = async () => {
    // if (claims?.client_proxy_login) {
    //   return
    // }
    setSwitchLoading(true)
    await axiomFetch('/internal/client/switch', 'POST', {
      cont_cell_num: profile.CONT_CELL_NUM,
      switch_to_acct: profile.ACCOUNT_ID === 1320 ? 1560 : 1320,
      profile: profile,
    })
    // Object.keys(localStorage).forEach((key) => {
    //   if (key.includes('auth0spajs')) {
    //     localStorage.removeItem(key)
    //   }
    // })
    return loginWithRedirect()
    setSwitchLoading(false)
  }

  return (
    <div className='flex flex-col'>
      <div className='flex h-[72px] justify-between px-5 py-3 items-center border-b border-[#CCDDFF]'>
        <img
          src={images_logos.axiom_large}
          alt='Axiom'
          className={`max-h-8 w-auto ${
            navigateHome || get_storage('uuid') ? '' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (navigateHome || get_storage('uuid')) {
              return
            }
            localStorage.setItem('vbc_edit_mode', '')
            saveReceiver((prev) => ({ ...prev, edit_mode: '', edit_vbcid: '' }))
            navigate('/user')
          }}
        />
        {navigateHome || get_storage('uuid') ? (
          ''
        ) : (
          <div>
            {switchLoading ? (
              <div>
                <SmallLoadingRel />
              </div>
            ) : (
              <div
                className={`text-[15px]  ${
                  false // claims?.client_proxy_login
                    ? 'bg-black bg-gradient-to-r from-gray-600 to-gray-300 rounded-[50%] p-[3px] text-white'
                    : profile?.ST_ABBR === 'AZ'
                    ? 'cursor-pointer bg-black bg-gradient-to-b from-[#73DFE7] to-[#0063F7] rounded-[50%] p-[3px] text-white'
                    : 'cursor-pointer bg-black bg-gradient-to-b from-[#00CFDE] to-[#05A660] rounded-[50%] p-[3px] text-white'
                }`}
                onClick={switch_accounts}
              >
                {profile?.ST_ABBR || 'AZ'}
              </div>
            )}
          </div>
        )}
        <div className='mt-[10px] ml-[50px]'>
          <Bugs />
        </div>

        {showConversations & (type !== 'vbc') ? (
          <div
            className='flex gap-[2px] cursor-pointer'
            onClick={() => {
              navigate('/user/conversations')
            }}
          >
            <img src={ch_images.add_blue} />
            <NotificationsIcon
              count={get_count('conversations')}
              loading={loadingConversations}
            />
          </div>
        ) : (
          ''
        )}

        {showTours &&
        type !== 'vbc' &&
        listingTours?.data?.event_pages?.find(
          (item) =>
            Number(item?.event_id) ===
            Number(listingTours?.tour_state?.event_id)
        ) ? (
          <div
            className='flex gap-[2px] cursor-pointer'
            onClick={() => {
              navigate('/user/listing_tours')
            }}
          >
            <img src={images_tours.home_blue} />
          </div>
        ) : (
          ''
        )}

        <Menu type={type} />
      </div>
      {claims?.user_id ? (
        <div className='flex justify-end items-center'>
          {show_agent_toggle ? (
            <div className='w-full flex justify-center mt-[10px]'>
              <CircleCheckToggle
                name={`is_internal`}
                data-cy='is_internal_toggle'
                type='toggle'
                toggle_left={true}
                toggle_left_label={'Employee View'}
                toggle_right_label={'Agent View'}
                inputValue={claims}
                handle_change={async (e) => {
                  saveClaims((prev) => {
                    window.localStorage.setItem(
                      'is_internal',
                      prev?.is_internal === 'Y' ? 'N' : 'Y'
                    )
                    return {
                      ...prev,
                      is_internal: prev?.is_internal === 'Y' ? 'N' : 'Y',
                    }
                  })
                }}
              />
            </div>
          ) : (
            ''
          )}
          {claims?.user_id &&
          profile?.DEPARTMENT?.toLowerCase() === 'admin' &&
          !pathname.includes('/card') ? (
            <div className='flex items-center'>
              <span>admin</span>
              <CircleCheckToggle
                data-cy='admin_check'
                name={`is_admin`}
                type='check'
                inputValue={profile}
                handle_change={async (e) => {
                  if (claims?.ws?.send) {
                    claims?.ws?.send('hello admin changed')
                  }
                  saveProfile((prev) => {
                    window.localStorage.setItem(
                      'is_admin',
                      prev?.is_admin === 'Y' ? 'N' : 'Y'
                    )
                    return {
                      ...prev,
                      is_admin: prev?.is_admin === 'Y' ? 'N' : 'Y',
                    }
                  })
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {claims?.client_proxy_login ? (
        <div className='flex justify-center my-[10px]'>
          {loading ? (
            <SmallLoadingRel />
          ) : (
            <button
              className={`${uiStyles.hover_btn_small}`}
              onClick={async () => {
                setLoading(true)
                await axiomFetch('/internal/client/login', 'POST', {
                  actual_phone:
                    claims?.login_phone_number || user?.name?.replace('+1', ''),
                  current_phone:
                    claims?.login_phone_number || user?.name?.replace('+1', ''),
                })
                return loginWithRedirect()
              }}
            >
              Client Logout
            </button>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default Header2
